import React from "react";
import PropTypes from "prop-types";

function AlarmOnIcon({ color, className, onClick, width }) {
  let iconClasses = ["shared__icon", "alarm-on-icon", `shared__fill-${color}`];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  return (
    <svg
      className={iconClasses.join(" ")}
      onClick={onClick}
      onKeyPress={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={`${width}px`}
      height={`${width}px`}
      viewBox={`0 0 ${width} ${width}`}>
      <title>Alarm On</title>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M10.54 14.53L8.41 12.4l-1.06 1.06 3.18 3.18 6-6-1.06-1.06zm6.797-12.72l4.607 3.845-1.28 1.535-4.61-3.843zm-10.674 0l1.282 1.536L3.337 7.19l-1.28-1.536zM12 4c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" />
    </svg>
  );
}

AlarmOnIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  width: PropTypes.number,
};

AlarmOnIcon.defaultProps = {
  color: "defaultText",
  className: "",
  onClick: () => {},
  width: 32,
};

AlarmOnIcon.displayName = "AlarmOnIcon";

export default AlarmOnIcon;
