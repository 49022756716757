import React from "react";
import PropTypes from "prop-types";

function MinusIcon({ color, className, onClick, width }) {
  let iconClasses = ["shared__icon", "minus-icon"];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  return (
    <svg
      className={iconClasses.join(" ")}
      onClick={onClick}
      onKeyPress={onClick}
      width={`${width}px`}
      height={`${width}px`}
      viewBox={`0 0 ${width} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Minus</title>
      <g
        id="Bag-Copy-9"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <path
          d="M16,8 C20.418278,8 24,11.581722 24,16 C24,20.418278 20.418278,24 16,24 C11.581722,24 8,20.418278 8,16 C8,11.581722 11.581722,8 16,8 Z M16,10 C12.6862915,10 10,12.6862915 10,16 C10,19.3137085 12.6862915,22 16,22 C19.3137085,22 22,19.3137085 22,16 C22,12.6862915 19.3137085,10 16,10 Z M12,15 L20,15 L20,17 L12,17 L12,15 Z"
          id="Combined-Shape"
          className={`shared__fill-${color}`}
          fillRule="nonzero"
        />
        <polygon
          id="Path"
          points={`0 0 ${width} 0 ${width} ${width} 0 ${width}`}
        />
      </g>
    </svg>
  );
}

MinusIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  width: PropTypes.number,
};

MinusIcon.defaultProps = {
  color: "defaultText",
  className: "",
  onClick: () => {},
  width: 32,
};

MinusIcon.displayName = "MinusIcon";

export default MinusIcon;
