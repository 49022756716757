import React from "react";
import PropTypes from "prop-types";

function CloseIcon({ color, className, onClick, width, style, type }) {
  let iconClasses = ["shared__icon", "close-icon"];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  if (type === "thin") {
    return (
      <svg
        className={iconClasses.join(" ")}
        style={style}
        onClick={onClick}
        onKeyPress={onClick}
        width={`${width}px`}
        height={`${width}px`}
        viewBox="0 0 122.878 122.88"
        enableBackground="new 0 0 122.878 122.88"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Close</title>
        <g>
          <path
            className={`shared__fill-${color}`}
            fillRule="nonzero"
            d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z"
          />
        </g>
      </svg>
    );
  }
  return (
    <svg
      className={iconClasses.join(" ")}
      style={style}
      onClick={onClick}
      onKeyPress={onClick}
      width={`${width}px`}
      height={`${width}px`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 121.31 122.876"
      enableBackground="new 0 0 121.31 122.876"
      xmlSpace="preserve">
      <title>Close</title>
      <g>
        <path
          className={`shared__fill-${color}`}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"
        />
      </g>
    </svg>
  );
}

CloseIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  width: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
};

CloseIcon.defaultProps = {
  color: "defaultText",
  className: "",
  onClick: () => {},
  width: 32,
  style: null,
  type: "thin",
};

CloseIcon.displayName = "CloseIcon";

export default CloseIcon;
