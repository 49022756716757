import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import CaretIcon from "../components/atoms/icons/caret";
import CartIcon from "../components/atoms/icons/cart-icon";
import PlusIcon from "../components/atoms/icons/plus";
import MinusIcon from "../components/atoms/icons/minus";
import BagEmptyIcon from "../components/atoms/icons/bag-empty";
import AlarmOnIcon from "../components/atoms/icons/alarm-on";
import CloseIcon from "../components/atoms/icons/close";
import ReloadIcon from "../components/atoms/icons/reload";

function DesignIcons() {
  return (
    <div className="design__content">
      <SEO title="Design System - Icons" />
      <h2>Icons</h2>
      <p className="design__text p2">
        Icons currently have a set size of 24x24 pixels.
      </p>

      <div className="design__stories">
        <DesignStory
          title="caret"
          type="component"
          component={CaretIcon}
          compact
        />
        <DesignStory
          title="cart"
          type="component"
          component={CartIcon}
          compact
        />
        <DesignStory
          title="plus"
          type="component"
          component={PlusIcon}
          compact
        />
        <DesignStory
          title="minus"
          type="component"
          component={MinusIcon}
          compact
        />
        <DesignStory
          title="bag empty"
          type="component"
          component={BagEmptyIcon}
          compact
        />
        <DesignStory
          title="alarm on"
          type="component"
          component={AlarmOnIcon}
          compact
        />
        <DesignStory
          title="close"
          type="component"
          component={CloseIcon}
          compact
          props={{ style: { padding: 8 } }}
        />
        <DesignStory
          title="close (thick)"
          type="component"
          component={CloseIcon}
          compact
          props={{ style: { padding: 8 }, type: "thick" }}
        />
        <DesignStory
          title="reload"
          type="component"
          component={ReloadIcon}
          compact
          props={{ style: { padding: 8 } }}
        />
        <DesignStory
          title="realod (thick)"
          type="component"
          component={ReloadIcon}
          compact
          props={{ style: { padding: 8 }, type: "thick" }}
        />

        <div className="design__story-section">
          <p className="design__text p2">
            Props can be added to customize the icon color.
          </p>
          <DesignStory
            title="buffy orange cart"
            type="component"
            component={CartIcon}
            compact
            props={{ color: "buffyOrange" }}
          />
          <DesignStory
            title="eucalyptus plus"
            type="component"
            component={PlusIcon}
            compact
            props={{ color: "eucalyptus" }}
          />
        </div>

        <div className="design__story-section">
          <p className="design__text p2">
            Additionally, a prop for click handler can be provided.
          </p>
          <DesignStory
            title="click the cart icon!"
            type="component"
            component={CartIcon}
            compact
            props={{ onClick: () => alert("click on cart") }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={CartIcon} title="Icon Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignIcons;
