import React from "react";
import PropTypes from "prop-types";

function BagEmptyIcon({ color, className, onClick, width }) {
  let iconClasses = ["shared__icon", "bag-empty-icon"];
  if (className) {
    iconClasses = iconClasses.concat(
      Array.isArray(className) ? className : className.split(" "),
    );
  }

  return (
    <svg
      className={iconClasses.join(" ")}
      onClick={onClick}
      onKeyPress={onClick}
      width={`${width}px`}
      height={`${width}px`}
      viewBox={`0 0 ${width} ${width}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Bag Empty</title>
      <g
        id="Bag-Copy-5"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <polygon
          id="Path"
          points={`0 0 ${width} 0 ${width} ${width} 0 ${width}`}
        />
        <g
          id="Group-2"
          transform="translate(5.000000, 3.000000)"
          className={`shared__fill-${color}`}
          fillRule="nonzero">
          <path
            d="M14,0 C15.1045695,0 16,0.8954305 16,2 L16,2 L16,4 L16,4 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,7 L22,23 C22,24.6568542 20.6568542,26 19,26 L19,26 L3,26 C1.34314575,26 0,24.6568542 0,23 L0,23 L0,7 C0,5.34314575 1.34314575,4 3,4 L3,4 L6,4 L6,2 C6,0.8954305 6.8954305,0 8,0 L8,0 Z M19,6 L3,6 C2.44771525,6 2,6.44771525 2,7 L2,7 L2,23 C2,23.5522847 2.44771525,24 3,24 L3,24 L19,24 C19.5522847,24 20,23.5522847 20,23 L20,23 L20,7 C20,6.44771525 19.5522847,6 19,6 L19,6 Z M14,2 L8,2 L8,4 L14,4 L14,2 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </svg>
  );
}

BagEmptyIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onClick: PropTypes.func,
  width: PropTypes.number,
};

BagEmptyIcon.defaultProps = {
  color: "defaultText",
  className: "",
  onClick: () => {},
  width: 32,
};

BagEmptyIcon.displayName = "BagEmptyIcon";

export default BagEmptyIcon;
